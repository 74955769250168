body {
  font-family: "Work Sans", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
}
.form-control {
  box-shadow: none !important;
  height: 50px;
}

.bg-color {
  background-color: #00008b;
}
.bg-color2 {
  background-color: #fef2e9;
}
.bg-color3 {
  background-color: #059B4926;
}
.bg-color3main {
  background-color: #059B49;
}
.color {
  color: #00008b;
}
.color2 {
  color: #F27A23;
}
.color3 {
  color: #059B49;
}
.bg-color-second {
  background-color: #d6d6ff;
}
.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cursor {
  cursor: pointer;
}

.bgImage {
  background-image: url("../src//Images//commanbanner.svg");
  background-size: cover;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.teambgcolor {
  background-color: #c4c4c480;
}
.fit-img {
  object-fit: cover;
}
.form-control {
  border: 1px solid #00008b;
}
.btn-style {
  border: none;
  background-color: #059B49;
  border-radius: 0.3rem;
  font-size: larger;
  color: white;
  padding: 0.5rem 1rem 0.5rem 1rem;
}
.btn-style:hover {
  border: 1px solid #059B49;
  background-color: white;
  border-radius: 0.3rem;
  font-size: larger;
  color: #059B49;
  padding: 0.5rem 1rem 0.5rem 1rem;
}
.btn-style-2 {
  border: 1px solid #059B49;
  background-color: white;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  color: #059B49;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s, color 0.3s; /* Add smooth transition */
}

.btn-style-2:hover {
  background-color: #059B49;
  color: white;
}
.content-center {
  display: flex;
  justify-items: center;
  align-items: center;
}
.whatsappicon{
  display:flex;
  position: fixed;
  right: 15px;
  bottom:15px;
}


.btn-style-3 {
  border: 1px solid #059B49;
  background-color: white;
  border-radius: 0.3rem;
  font-size: larger;
  color: #059B49;
  padding: 0.5rem 1rem 0.5rem 1rem;
}
.btn-style-3:hover{
  background-color: #059B49;
  color: white;
}