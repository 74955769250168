.description-text {
    display: inline; /* Ensure text and link are on the same line */
    white-space: pre-wrap; /* Preserve white spaces and line breaks */
  }
  
  .read-more-less {
    color: #00008B; /* Set color for the link */
    cursor: pointer; /* Pointer cursor for clickable links */
    margin-left: 5px; /* Adjust space between text and link */
  }
  