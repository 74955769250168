.service-image{
    margin-top: -50px;
    margin-left:-1px;

}
.service{
    border: 3px solid #d6d6ff;
}
.hover:hover{
    border: 3px solid #059B49;
    transition: 5ms all;
    
}