.card {
    position: relative; /* Ensure the card is positioned for absolute children */
    transition: transform 0.3s;
}

.card:hover {
    transform: scale(1.05);
}

.card .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
}

.card:hover .overlay {
    opacity: 1;
}

.overlay .social-icon {
    font-size: 1.5rem;
    color: #fff;
    margin: 0 10px;
    transition: transform 0.3s;
    z-index: 2; /* Ensure social icons are above the overlay */
}

.overlay .social-icon:hover {
    transform: scale(1.2);
}
