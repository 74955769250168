/* Overlay */
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 60%; /* Adjusted to cover more of the image */
    height: 100%;
    background: linear-gradient(to top right, #00008B40 60%, transparent 60%); /* Adjusted diagonal gradient */
    z-index: 1;
  }
  
  
  /* Adjusting text position and size */
  .carousel-caption {
    z-index: 2;
    text-align: left;
    left: 20px;
    right: 20px;
  }
  
  /* Ensuring the text does not overlap on smaller screens */
  .hero-title, .hero-subtitle {
    font-size: 3vw;
    word-wrap: break-word;
  }
  
  /* Responsive adjustments */
  @media (max-width: 767px) {
    .hero-title, .hero-subtitle {
      font-size: 4.5vw;
    }
    .carousel-caption {
      bottom: 20px;
    }
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .pulse-animation {  
    animation: rotate 10s infinite linear; 
  }
  
  