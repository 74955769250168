
.Get_App:hover{
    cursor: pointer;
}

.Get_App a{
    color: #00008B;
}
.nav-link{
    color:#00008B;
    font-weight: 600;
    font-size: 20px;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom:0;
    padding-top:0;
}
.navbar-pill{
    color: #00008B !important;
    font-weight: bold;
    /* border-bottom:2px solid #FF904C ; */

}
.navbar-mobile{
    color: #00008B !important;
}

#navbarNav{
    justify-content: end;
}

.top-navbar-icon{
    border: none;
    background-color: #00008B;
}

.top-navbar-icon div{
    max-width: 120px;
    display: flex;
    justify-content: space-between;
}

.home_icon, .worker_icon, .profile_icon{
    background-color: #00008B;
    padding: 8px 12px;
    border-radius: 50%;
}

.bottom-nav{
  border-radius: 20px 20px 0 0;
  border: 1px solid rgb(205, 192, 192);
}



@media only screen and (min-width: 768px) {
    .bottom-nav-section{
        display: none;
    }

    .top-navbar-icon{
        display: none;
    }
  }

.img-fit{
    object-fit: cover;
}

/* Add this CSS for sticky navbar and animation */
.navbar-sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    animation: slideDown 0.5s ease-in-out;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Add your animation */
  @keyframes slideDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  
