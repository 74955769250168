
.footer-bg{
    background-color: #b938d33a;
    padding-top: 30px;
    padding-bottom: 30px;
}

.term-and-policy{
    display: flex;
    justify-content: end;
}

.social_icons i{
    font-size: 22px;
}

a{
    text-decoration: none;
    color:black ;
}

.blog-title{
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .term-and-policy{
        display: flex;
        justify-content: center;
        padding-bottom: 60px;
    }

    .powered-by{
        display: flex;
        justify-content: center;
    }
  }

  @media only screen and (max-width: 768px) {

    .blog-title{
        text-align: start;
    }

    .powered-by{
        text-align: center;
    }
  }